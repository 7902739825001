export const getAllUsersService = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users/all`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                'Content-Type': 'application/json',
            },
        });

        const json = await response.json();

        return json;
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);

        return errorItem;
    }
};

export const paginateUsersService = async (page, search = '') => {
    try {
        const size = 10;
        const orderBy = {
            f_name: 'ASC',
            m_name: 'ASC',
            l_name: 'ASC',
        };

        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/profiles?search=${search}&size=${size}&page=${page}`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        const json = await response.json();

        return json;
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);

        return errorItem;
    }
};

export const addUserService = async (user) => {
    try {
        const body = {
            email: user.email,
            username: user.username,
            password: user.password,
            password_confirmation: user.password_confirmation,
            f_name: user.f_name,
            m_name: user.m_name || 'null',
            l_name: user.l_name,
            dob: user.dob,
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        const json = await response.json();

        return json;
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);

        return errorItem;
    }
};

export const updateUserService = async (userId, user) => {
    try {
        const body = {
            user: user.email,
            username: user.username,
            password: user.password,
            password_confirmation: user.password_confirmation,
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${userId}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        const json = await response.json();

        return json;
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);

        return errorItem;
    }
};

export const updateProfileService = async (userProfileId, userProfile, selectedFile) => {
    try {
        const formData = new FormData();
        formData.append('f_name', userProfile.profile.f_name);
        formData.append('m_name', userProfile.profile.m_name || 'null');
        formData.append('l_name', userProfile.profile.l_name);
        formData.append('dob', userProfile.profile.dob);
        formData.append('about', userProfile.profile.about || '');
        formData.append('card_bg_color', userProfile.profile.card_bg_color);
        formData.append('card_font_color', userProfile.profile.card_font_color);
        formData.append('card_text_color', userProfile.profile.card_text_color);
        if (selectedFile) {
            formData.append('card_profile_picture_link', selectedFile);
        }
        formData.append('card_bg_image_link', userProfile.profile.card_bg_image_link);
        formData.append('suffix', userProfile.profile.suffix || null);

        const response = await fetch(`${process.env.REACT_APP_API_URL}/user-profiles/${userProfileId}`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
            },
            body: formData,
        });

        const json = await response.json();

        return json;
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);

        return errorItem;
    }
};

export const viewUserProfileService = async (slug) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/profiles/view/${slug}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const json = await response.json();

        return json;
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);

        return errorItem;
    }
};

export const getCurrentUserService = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/me`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                'Content-Type': 'application/json',
            },
        });

        const json = await response.json();

        return json;
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);

        return errorItem;
    }
};