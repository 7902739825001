import { useContext, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import PropTypes, { object } from 'prop-types';

import { UserContext } from '../../context/UserContext';
import { ScrollContext } from '../../context/ScrollProvider';

import { useScrollBlock } from '../../hooks/useScrollBlock';

import prepareClassNames from '../../utils/prepareClassNames';

import { updateAllAction } from '../../state/actions/userActions';

import Button from '../Button/Button';

import './Nav.styles.scss';

const Nav = ({ arrayOfElements }) => {
    const baseClasses = prepareClassNames(['nav__link', 'flex']);
    const activeClasses = prepareClassNames(['nav__link', 'nav__link--active', 'flex']);
    const activeContactClasses = prepareClassNames(['nav__link', 'nav__link--active', 'nav__link--orange', 'flex']);
    const [blockScroll, allowScroll] = useScrollBlock();

    const navigate = useNavigate();
    const { user, dispatchUser, isLoggedIn, setIsLoggedIn } = useContext(UserContext);
    const { setScrollBottom } = useContext(ScrollContext);

    const userProfileId = isLoggedIn && user.profile !== null ? user.profile.id : undefined;

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleLogOut = () => {
        localStorage.removeItem('token');

        dispatchUser(updateAllAction({}));
        setIsLoggedIn(false);

        navigate('/', { replace: true });
    };

    const handleMenuOpen = () => {
        setIsMenuOpen(!isMenuOpen);

        if (isMenuOpen) {
            allowScroll();
        } else {
            blockScroll();
        }
    };

    const handleSwitchToContacts = () => {
        navigate('/', { replace: true });
        setScrollBottom(true);
    };

    return (
        <nav className="nav">
            <div
                className={`nav__container ${
                    isMenuOpen ? 'nav__container--open' : ''
                } flex align-center justify-space`}>
                <Link to="/" className="logo">
                    SLCK
                </Link>

                <div className="nav__links flex align-center">
                    <ul className="nav__list flex align-center">
                        {arrayOfElements.map((item, index) => {
                            return (
                                <li className="nav__item" key={index}>
                                    {item.nav ? (
                                        <NavLink
                                            className={({ isActive }) => (isActive ? activeClasses : baseClasses)}
                                            to={item.path}>
                                            {item.content}
                                        </NavLink>
                                    ) : (
                                        <ScrollLink
                                            activeClass="nav__link--active"
                                            className={baseClasses}
                                            to={item.path}
                                            spy={true}>
                                            {item.content}
                                        </ScrollLink>
                                    )}
                                </li>
                            );
                        })}

                        {isLoggedIn ? (
                            <>
                                <li className="nav__item">
                                    <NavLink
                                        className={({ isActive }) => (isActive ? activeContactClasses : baseClasses)}
                                        to={`/users/profiles/${userProfileId}`}>
                                        Profile
                                    </NavLink>
                                </li>

                                <li className="nav__item">
                                    <NavLink
                                        className={({ isActive }) => (isActive ? activeContactClasses : baseClasses)}
                                        to={`/contacts/${user.id}`}>
                                        Contacts
                                    </NavLink>
                                </li>
                            </>
                        ) : null}
                    </ul>

                    {isLoggedIn ? (
                        <button type="button" className="nav__log" onClick={handleLogOut}>
                            Log Out
                        </button>
                    ) : (
                        <NavLink className="nav__log" to="/log-in">
                            Log In
                        </NavLink>
                    )}
                </div>

                {window.innerWidth < 996 ? (
                    <div className="nav-burger__container flex align-center">
                        <Button gold={true} small={true} eventHandler={handleSwitchToContacts}>
                            Get Yours Now!
                        </Button>

                        <button
                            type="button"
                            className="nav-burger flex flex--column justify-space"
                            onClick={handleMenuOpen}>
                            <span className="nav-burger__line nav-burger__line-1"></span>
                            <span className="nav-burger__line nav-burger__line-2"></span>
                            <span className="nav-burger__line nav-burger__line-3"></span>
                        </button>
                    </div>
                ) : null}
            </div>
        </nav>
    );
};

Nav.propTypes = {
    arrayOfElements: PropTypes.arrayOf(object).isRequired,
};

export default Nav;
