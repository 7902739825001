import {Suspense, lazy} from 'react';
import {Route, Routes} from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import Loader from '../components/Loader/Loader';

import {ContactsProvider} from '../context/ContactsContext';

const Home = lazy(() => import('../views/Public/Home/Home'));
const LogIn = lazy(() => import('../views/Public/LogIn/LogIn'));
const Register = lazy(() => import('../views/Public/Register/Register'));
const RegisterSuccessful = lazy(() => import('../views/Public/RegisterSuccessful/RegisterSuccessful'));
const ChangePassword = lazy(() => import('../views/Private/ChangePassword/ChangePassword'));
const ForgotPassword = lazy(() => import('../views/Public/ForgotPassword/ForgotPassword'));
const ResetPassword = lazy(() => import('../views/Public/ResetPassword/ResetPassword'));
const UserLayout = lazy(() => import('../layouts/UserLayout/UserLayout'));
const UserProfile = lazy(() => import('../views/Private/UserProfile/UserProfile'));
const EditUserProfile = lazy(() => import('../views/Private/EditUserProfile/EditUserProfile'));
const OtherUserPage = lazy(() => import('../views/Private/OtherUserPage/OtherUserPage'));
const ContactsLayout = lazy(() => import('../layouts/ContactsLayout/ContactsLayout'));
const UserContacts = lazy(() => import('../views/Private/UserContacts/UserContacts'));
const FindContacts = lazy(() => import('../views/Public/FindContacts/FindContacts'));

const MainRoutes = () => {
    return (
        <Suspense fallback={<Loader/>}>
            <Routes>
                <Route index path="/" element={<Home/>}/>
                <Route path="/log-in" element={<LogIn/>}/>
                <Route path="/register" element={<Register/>}/>
                <Route path="/register/successful" element={<RegisterSuccessful/>}/>
                <Route
                    path="/change-password"
                    element={
                        <ProtectedRoute>
                            <ChangePassword/>
                        </ProtectedRoute>
                    }
                />
                <Route path="/forgot-password" element={<ForgotPassword/>}/>
                <Route path="/reset-password" element={<ResetPassword/>}/>
                <Route path="/users" element={<UserLayout/>}>
                    <Route
                        path="profiles/:userProfileId"
                        element={
                            <ProtectedRoute>
                                <UserProfile/>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="profiles/:userProfileId/edit"
                        element={
                            <ProtectedRoute>
                                <EditUserProfile/>
                            </ProtectedRoute>
                        }
                    />
                    <Route path="profiles/view/:slug" element={<OtherUserPage/>}/>
                </Route>
                <Route
                    path="/contacts"
                    element={
                        <ContactsProvider>
                            <ContactsLayout/>
                        </ContactsProvider>
                    }>
                    <Route index path=":userId" element={<UserContacts/>}/>
                    <Route path=":userId/find" element={<FindContacts/>}/>
                </Route>
                <Route
                    path="*"
                    element={
                        <h2 style={{textAlign: 'center', color: 'var(--theme-color)'}}>
                            Sorry, nothing to see there 404
                        </h2>
                    }
                />
            </Routes>
        </Suspense>
    );
};

export default MainRoutes;