import 'react-toastify/dist/ReactToastify.css';
import './App.styles.scss';

import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import { getNavItems, getOtherPageNavItems } from '../data/navItemsData';

import Nav from '../components/Nav/Nav';

import { UserContext } from '../context/UserContext';
import { ScrollProvider } from '../context/ScrollProvider';

import MainRoutes from '../routes/MainRoutes';

import isFacebookBrowser from '../utils/isFacebookBrowser';

import Loader from '../components/Loader/Loader';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';

function App() {
    const { pathname } = useLocation();

    const { isLoading } = useContext(UserContext);

    useEffect(() => {
        if (isFacebookBrowser()) {
            const notificationId = 'facebook-browser-notification';

            toast.warn(
                'Facebook browser limits the functionality of the application, please open the application in the default browser.',
                {
                    toastId: notificationId,
                }
            );
        }
    }, []);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className="app">
            <ToastContainer theme="dark" autoClose={5000} closeButton={true} closeOnClick pauseOnFocusLoss draggable />

            <ScrollProvider>
                <header className="header">
                    {pathname === '/' ? (
                        <Nav arrayOfElements={getNavItems()} />
                    ) : (
                        <Nav arrayOfElements={getOtherPageNavItems()} />
                    )}
                </header>

                <main className="main">
                    <ScrollToTop>
                        <MainRoutes />
                    </ScrollToTop>
                </main>
            </ScrollProvider>
        </div>
    );
}

export default App;
