import {toast} from 'react-toastify';
import {getNotificationsSettings} from '../data/notificationsSettings';

export const registerService = async (user) => {
    const notificationId = 'register';
    const registerNotification = toast.loading('Please wait...', {toastId: notificationId});

    try {
        const body = {
            email: user.email,
            username: user.username,
            password: user.password,
            password_confirmation: user.password_confirmation,
            f_name: user.f_name,
            m_name: user.m_name || 'null',
            l_name: user.l_name,
            dob: user.dob,
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        if (response.status === 200 || response.status === 201) {
            const json = await response.json();

            toast.update(registerNotification, {
                render: 'Registration successful! Please verify your email.',
                type: 'success',
                isLoading: false,
                ...getNotificationsSettings(),
            });

            return json;
        }
    } catch (error) {
        const errorItem = error;

        toast.update(registerNotification, {
            render: 'Something went wrong, please try again!',
            type: 'error',
            isLoading: false,
            ...getNotificationsSettings(),
        });

        throw new Error(errorItem);
    }
};

export const loginService = async (user) => {
    const loginNotificationId = 'login';

    const loginNotification = toast.loading('Please wait...', {toastId: loginNotificationId});

    try {
        const body = {
            email: user.email,
            password: user.password,
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        if (response.status === 200 || response.status === 201) {
            const json = await response.json();

            toast.update(loginNotification, {
                render: 'Welcome back!',
                type: 'success',
                isLoading: false,
                ...getNotificationsSettings(),
            });

            return json;
        } else {
            toast.update(loginNotification, {
                render: 'Please provide correct login and password...',
                type: 'error',
                isLoading: false,
                ...getNotificationsSettings(),
            });
        }
    } catch (error) {
        console.error(error);
    }
};

export const changePasswordService = async (user) => {
    const notificationId = 'changePassword';
    const changePasswordNotification = toast.loading('Please wait...', {toastId: notificationId});

    try {
        const body = {
            password: user.password,
            password_confirmation: user.password_confirmation,
        };

        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/auth/change-password
`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            }
        );

        if (response.status === 200 || response.status === 201) {
            const json = await response.json();

            toast.update(changePasswordNotification, {
                render: 'Password changed successfully!',
                type: 'success',
                isLoading: false,
                ...getNotificationsSettings(),
            });

            return json;
        } else {
            toast.update(changePasswordNotification, {
                render: 'Something went wrong, please try again!',
                type: 'error',
                isLoading: false,
                ...getNotificationsSettings(),
            });
        }
    } catch (error) {
        console.error(error);
    }
};

export const getResetLink = async (data) => {
    const notificationId = 'forgotPassword';
    const resetPasswordNotification = toast.loading('Please wait...', {toastId: notificationId});

    try {
        const body = {
            email: data.email,
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/password/email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        if (response.status === 200 || response.status === 201) {
            const json = await response.json();

            toast.update(resetPasswordNotification, {
                render: 'Password reset link sent to your email!',
                type: 'success',
                isLoading: false,
                ...getNotificationsSettings(),
            });

            return json;
        } else {
            toast.update(resetPasswordNotification, {
                render: 'Something went wrong, please try again!',
                type: 'error',
                isLoading: false,
                ...getNotificationsSettings(),
            });
        }
    } catch (error) {
        console.error(error);
    }
};

export const resetPasswordService = async (data) => {
    const notificationId = 'resetPassword';
    const resetPasswordNotification = toast.loading('Please wait...', {toastId: notificationId});

    try {
        const body = {
            token: data.token,
            email: data.email,
            password: data.password,
            password_confirmation: data.password_confirmation,
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/password/reset`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        if (response.status === 200 || response.status === 201) {
            const json = await response.json();

            toast.update(resetPasswordNotification, {
                render: 'Password changed successfully!',
                type: 'success',
                isLoading: false,
                ...getNotificationsSettings(),
            });

            return json;
        } else {
            toast.update(resetPasswordNotification, {
                render: 'Something went wrong, please try again!',
                type: 'error',
                isLoading: false,
                ...getNotificationsSettings(),
            });
        }
    } catch (error) {
        console.error(error);
    }
};