import { createContext, useLayoutEffect, useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getCurrentUserService } from '../services/usersService';

import editUserReducer from '../state/reducers/editUserReducer';
import { updateAllAction } from '../state/actions/userActions';

const UserContext = createContext({});

const UserProvider = ({ children }) => {
    const token = localStorage.getItem('token');

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, dispatchUser] = useReducer(editUserReducer, {});

    const [isQrShown, setIsQrShown] = useState(false);
    const [userQr, setUserQr] = useState('');

    const userState = {
        user,
        isLoggedIn,
        dispatchUser,
        setIsLoggedIn,
        isLoading,
        setIsLoading,
        isQrShown,
        setIsQrShown,
        userQr,
        setUserQr,
    };

    const getCurrentUser = async () => {
        const response = await getCurrentUserService();

        if (response) {
            dispatchUser(updateAllAction(response));

            setIsLoading(false);
            setIsLoggedIn(true);
        }
    };

    useLayoutEffect(() => {
        if (token) {
            getCurrentUser();
        } else {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        const handleLogout = () => {
            if (token) {
                setIsLoggedIn(false);
                navigate('/', { replace: true });
            } else {
                getCurrentUser().then(() => navigate(`/users/profiles/${user.profile.id}`, { replace: true }));
            }
        };

        window.addEventListener('storage', handleLogout);

        return () => {
            window.removeEventListener('storage', handleLogout);
        };
    }, [isLoggedIn, token, setIsLoggedIn]);

    return <UserContext.Provider value={userState}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider };
