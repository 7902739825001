import './Button.styles.scss';

import PropTypes from 'prop-types';

import prepareClassNames from '../../utils/prepareClassNames';

const Button = ({ gold = false, small = false, type = '', disabled, eventHandler, children }) => {
    const classNames = ['btn', gold ? 'btn--gold' : null, small ? 'btn--small' : null];
    const joinedClassNames = prepareClassNames(classNames);

    return (
        <button type={type ? type : 'button'} className={joinedClassNames} onClick={eventHandler} disabled={disabled}>
            {children}
        </button>
    );
};

Button.propTypes = {
    gold: PropTypes.bool,
    small: PropTypes.bool,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    eventHandler: PropTypes.func,
};

export default Button;
