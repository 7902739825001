import { createContext, useState } from 'react';

const ScrollContext = createContext({});

const ScrollProvider = ({ children }) => {
    const [scrollBottom, setScrollBottom] = useState(false);

    const scrollState = {
        scrollBottom,
        setScrollBottom,
    };

    return <ScrollContext.Provider value={scrollState}>{children}</ScrollContext.Provider>;
};

export { ScrollContext, ScrollProvider };
