const navItemsData = [
    {
        path: 'promo',
        content: 'Home',
    },

    {
        path: 'about',
        content: 'About',
    },

    {
        path: 'how',
        content: 'How it Works',
    },

    {
        path: 'support',
        content: 'Support',
    },
];

const otherPageNavItemsData = [
    {
        path: '/',
        content: 'Home',
        nav: true,
    },

    {
        path: '/',
        content: 'About',
        nav: true,
    },

    {
        path: '/',
        content: 'How it Works',
        nav: true,
    },

    {
        path: '/',
        content: 'Support',
        nav: true,
    },
];

export const getNavItems = () => navItemsData;
export const getOtherPageNavItems = () => otherPageNavItemsData;
