import { toast } from 'react-toastify';
import { getNotificationsSettings } from '../data/notificationsSettings';

export const getAllLinksService = async (userProfileId) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user-profiles/${userProfileId}/links`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.status === 200) {
            const json = await response.json();

            return json;
        }
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);

        return errorItem;
    }
};

export const addNewLinksService = async (userProfileId, link) => {
    try {
        const body = {
            type_id: link.type_id,
            sub_type_id: link.sub_type_id,
            url: link.url,
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/user-profiles/${userProfileId}/links`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        if (response.status === 200 || response.status === 201) {
            const json = await response.json();

            return json;
        }
    } catch (error) {
        const errorItem = error;

        toast.error('Something went wrong adding new link... Please try again.');
        console.log(errorItem);

        return errorItem;
    }
};

export const deleteLinkService = async (userProfileId, linkId) => {
    const deleteLinkNotification = toast.loading('Please wait...');

    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/user-profiles/${userProfileId}/links/${linkId}`,
            {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        if (response.status === 200 || response.status === 201) {
            const json = await response.json();

            toast.update(deleteLinkNotification, {
                render: 'Link deleted successfully!',
                type: 'success',
                isLoading: false,
                ...getNotificationsSettings(),
            });

            return json;
        }
    } catch (error) {
        const errorItem = error;

        toast.update(deleteLinkNotification, {
            render: 'Something went wrong deleting link... Please try again.',
            type: 'error',
            isLoading: false,
            ...getNotificationsSettings(),
        });
        console.log(errorItem);

        return errorItem;
    }
};

export const getAllContactNumbersService = async (userProfileId) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/user-profiles/${userProfileId}/contact-numbers`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        if (response.status === 200) {
            const json = await response.json();

            return json;
        }
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);

        return errorItem;
    }
};

export const addNewContactNumberService = async (userProfileId, contactNumber) => {
    try {
        const body = {
            type_id: contactNumber.type_id,
            num: contactNumber.num,
            country_code: contactNumber.country_code,
            area_code: contactNumber.area_code,
        };

        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/user-profiles/${userProfileId}/contact-numbers`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            }
        );

        if (response.status === 200 || response.status === 201) {
            const json = await response.json();

            return json;
        }
    } catch (error) {
        const errorItem = error;

        toast.error('Something went wrong adding new phone number... Please try again.');
        console.log(errorItem);

        return errorItem;
    }
};

export const deleteContactNumberService = async (userProfileId, contactNumberId) => {
    const deleteContactNumberNotification = toast.loading('Please wait...');

    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/user-profiles/${userProfileId}/contact-numbers/${contactNumberId}`,
            {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        if (response.status === 200) {
            const json = await response.json();

            toast.update(deleteContactNumberNotification, {
                render: 'Phone number deleted successfully!',
                type: 'success',
                isLoading: false,
                ...getNotificationsSettings(),
            });

            return json;
        }
    } catch (error) {
        const errorItem = error;

        toast.update(deleteContactNumberNotification, {
            render: 'Something went wrong deleting phone number... Please try again.',
            type: 'error',
            isLoading: false,
            ...getNotificationsSettings(),
        });
        console.log(errorItem);

        return errorItem;
    }
};

export const getAllPositionsService = async (userProfileId) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user-profiles/${userProfileId}/positions`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.status === 200) {
            const json = await response.json();

            return json;
        }
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);

        return errorItem;
    }
};

export const addNewPositionService = async (userProfileId, position) => {
    try {
        const body = {
            position: position.position,
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/user-profiles/${userProfileId}/positions`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        if (response.status === 200 || response.status === 201) {
            const json = await response.json();

            return json;
        }
    } catch (error) {
        const errorItem = error;

        toast.error('Something went wrong adding new position... Please try again.');
        console.log(errorItem);

        return errorItem;
    }
};

export const deletePositionService = async (userProfileId, positionId) => {
    const deletePositionNotification = toast.loading('Please wait...');

    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/user-profiles/${userProfileId}/positions/${positionId}`,
            {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        if (response.status === 200) {
            const json = await response.json();

            toast.update(deletePositionNotification, {
                render: 'Position deleted successfully!',
                type: 'success',
                isLoading: false,
                ...getNotificationsSettings(),
            });

            return json;
        }
    } catch (error) {
        const errorItem = error;

        toast.update(deletePositionNotification, {
            render: 'Something went wrong deleting position... Please try again.',
            type: 'error',
            isLoading: false,
            ...getNotificationsSettings(),
        });
        console.log(errorItem);

        return errorItem;
    }
};

export const getAllWorkplacesService = async (userProfileId) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user-profiles/${userProfileId}/workplaces`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.status === 200) {
            const json = await response.json();

            return json;
        }
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);

        return errorItem;
    }
};

export const addNewWorkplaceService = async (userProfileId, workplace) => {
    try {
        const body = {
            name: workplace.name,
            description: workplace.description,
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/user-profiles/${userProfileId}/workplaces`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        if (response.status === 200 || response.status === 201) {
            const json = await response.json();

            return json;
        }
    } catch (error) {
        const errorItem = error;

        toast.error('Something went wrong adding new workplace... Please try again.');
        console.log(errorItem);

        return errorItem;
    }
};

export const deleteWorkplaceService = async (userProfileId, workplaceId) => {
    const deleteWorkplaceNotification = toast.loading('Please wait...');

    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/user-profiles/${userProfileId}/workplaces/${workplaceId}`,
            {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        if (response.status === 200) {
            const json = await response.json();

            toast.update(deleteWorkplaceNotification, {
                render: 'Workplace deleted successfully!',
                type: 'success',
                isLoading: false,
                ...getNotificationsSettings(),
            });

            return json;
        }
    } catch (error) {
        const errorItem = error;

        toast.update(deleteWorkplaceNotification, {
            render: 'Something went wrong deleting workplace... Please try again.',
            type: 'error',
            isLoading: false,
            ...getNotificationsSettings(),
        });
        console.log(errorItem);

        return errorItem;
    }
};

export const getAllVideosService = async (userProfileId) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user-profiles/${userProfileId}/videos`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.status === 200) {
            const json = await response.json();

            return json;
        }
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);

        return errorItem;
    }
};

export const addNewVideoService = async (userProfileId, video) => {
    try {
        console.log(video);

        const body = {
            url: video,
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/user-profiles/${userProfileId}/videos`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        if (response.status === 200 || response.status === 201) {
            const json = await response.json();

            return json;
        }
    } catch (error) {
        const errorItem = error;

        toast.error('Something went wrong adding new video link... Please try again.');
        console.log(errorItem);

        return errorItem;
    }
};

export const deleteVideoService = async (userProfileId, videoId) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/user-profiles/${userProfileId}/videos/${videoId}`,
            {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        if (response.status === 200) {
            const json = await response.json();

            return json;
        }
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);

        return errorItem;
    }
};

export const getAllOtherEmailsService = async (userProfileId) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user-profiles/${userProfileId}/emails`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.status === 200) {
            const json = await response.json();

            return json;
        }
    } catch (error) {
        console.log(error);
    }
};

export const addNewOtherEmailService = async (userProfileId, email) => {
    try {
        const body = {
            email: email.email,
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/user-profiles/${userProfileId}/emails`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        if (response.status === 200 || response.status === 201) {
            const json = await response.json();

            return json;
        }
    } catch (error) {
        const errorItem = error;

        toast.error('Something went wrong adding new email... Please try again.');
        console.log(errorItem);

        return errorItem;
    }
};

export const deleteOtherEmailService = async (userProfileId, emailId) => {
    const notificationId = 'delete-other-email';
    const deleteLinkNotification = toast.loading('Please wait...', {
        toastId: notificationId,
    });
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/user-profiles/${userProfileId}/emails/${emailId}`,
            {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        if (response.status === 200) {
            const json = await response.json();

            toast.update(deleteLinkNotification, {
                render: 'Email deleted successfully!',
                type: 'success',
                isLoading: false,
                ...getNotificationsSettings(),
            });

            return json;
        }
    } catch (error) {
        const errorItem = error;

        console.log(errorItem);
        toast.update(deleteLinkNotification, {
            render: 'Something went wrong deleting email... Please try again.',
            type: 'error',
            isLoading: false,
            ...getNotificationsSettings(),
        });

        return errorItem;
    }
};