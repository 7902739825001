const editUserReducer = (state, action) => {
    switch (action.type) {
        case 'user/updateAll':
            return {
                ...state,
                ...action.payload,
            };
        case 'user/updateProfile':
            return {
                ...state,
                profile: {
                    ...state.profile,
                    ...action.payload,
                },
            };
        case 'user/changeProfilePhoto':
            return {
                ...state,
                profile: {
                    ...state.profile,
                    card_profile_picture_url: action.payload,
                },
            };
        case 'user/changeCardBg':
            return {
                ...state,
                profile: {
                    ...state.profile,
                    card_bg_color: action.payload,
                },
            };
        case 'user/changeCardFontColor':
            return {
                ...state,
                profile: {
                    ...state.profile,
                    card_font_color: action.payload,
                },
            };
        case 'user/changeCardInformationColor':
            return {
                ...state,
                profile: {
                    ...state.profile,
                    card_text_color: action.payload,
                },
            };
        case 'user/changeFirstName':
            return {
                ...state,
                profile: {
                    ...state.profile,
                    f_name: action.payload,
                },
            };
        case 'user/changeMiddleName':
            return {
                ...state,
                profile: {
                    ...state.profile,
                    m_name: action.payload,
                },
            };
        case 'user/changeLastName':
            return {
                ...state,
                profile: {
                    ...state.profile,
                    l_name: action.payload,
                },
            };
        case 'user/changeSuffix':
            return {
                ...state,
                profile: {
                    ...state.profile,
                    suffix: action.payload,
                },
            };
        case 'user/changeUserPosition':
            return {
                ...state,
                profile: {
                    ...state.profile,
                    positions: action.payload,
                },
            };
        case 'user/changeUserEmails':
            return {
                ...state,
                profile: {
                    ...state.profile,
                    emails: action.payload,
                },
            };
        case 'user/changeUserWorkplaces':
            return {
                ...state,
                profile: {
                    ...state.profile,
                    workplaces: action.payload,
                },
            };
        case 'user/changeUserEmail':
            return {
                ...state,
                profile: {
                    ...state.profile,
                    email: action.payload,
                },
            };
        case 'user/changeUserNumber':
            return {
                ...state,
                profile: {
                    ...state.profile,
                    contact_numbers: action.payload,
                },
            };
        case 'user/changeUserAbout':
            return {
                ...state,
                profile: {
                    ...state.profile,
                    about: action.payload,
                },
            };
        case 'user/changeUserLinks':
            return {
                ...state,
                profile: {
                    ...state.profile,
                    links: action.payload,
                },
            };
        case 'user/changeUserVideoLink':
            return {
                ...state,
                profile: {
                    ...state.profile,
                    videos: action.payload,
                },
            };
        default:
            return state;
    }
};

export default editUserReducer;