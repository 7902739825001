import { createContext } from 'react';

import {
    registerService,
    loginService,
    changePasswordService,
    getResetLink,
    resetPasswordService,
} from '../services/authService';
import {
    getAllLinksService,
    addNewLinksService,
    deleteLinkService,
    getAllContactNumbersService,
    addNewContactNumberService,
    deleteContactNumberService,
    getAllPositionsService,
    addNewPositionService,
    deletePositionService,
    getAllWorkplacesService,
    addNewWorkplaceService,
    deleteWorkplaceService,
    getAllVideosService,
    addNewVideoService,
    deleteVideoService,
    getAllOtherEmailsService,
    addNewOtherEmailService,
    deleteOtherEmailService,
} from '../services/userProfileDetailsService';
import {
    getAllUsersService,
    paginateUsersService,
    addUserService,
    updateProfileService,
    viewUserProfileService,
    getCurrentUserService,
} from '../services/usersService';

const ApiContext = createContext({});

const ApiProvider = ({ children }) => {
    const apiState = {
        registerService,
        loginService,
        changePasswordService,
        getResetLink,
        resetPasswordService,
        getAllLinksService,
        addNewLinksService,
        deleteLinkService,
        getAllContactNumbersService,
        addNewContactNumberService,
        deleteContactNumberService,
        getAllPositionsService,
        addNewPositionService,
        deletePositionService,
        getAllWorkplacesService,
        addNewWorkplaceService,
        deleteWorkplaceService,
        getAllVideosService,
        addNewVideoService,
        deleteVideoService,
        getAllUsersService,
        paginateUsersService,
        addUserService,
        updateProfileService,
        viewUserProfileService,
        getCurrentUserService,
        getAllOtherEmailsService,
        addNewOtherEmailService,
        deleteOtherEmailService,
    };

    return <ApiContext.Provider value={apiState}>{children}</ApiContext.Provider>;
};

export { ApiContext, ApiProvider };