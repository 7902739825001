import { createContext, useState } from 'react';

const ContactsContext = createContext({});

const ContactsProvider = ({ children }) => {
    const [search, setSearch] = useState('');
    const [sort, setSort] = useState(0);

    const contactsState = {
        search,
        sort,
        setSearch,
        setSort,
    };

    return <ContactsContext.Provider value={contactsState}>{children}</ContactsContext.Provider>;
};

export { ContactsContext, ContactsProvider };
