import './Loader.styles.scss';

import PropTypes from 'prop-types';

const Loader = ({ testId, small }) => {
    return (
        <div className={`loader ${small ? 'loader--small' : ''}`} data-testid={testId}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
        </div>
    );
};

Loader.propTypes = {
    testId: PropTypes.string,
    small: PropTypes.bool,
};

export default Loader;
