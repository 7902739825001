export const updateAllAction = (user) => ({
    type: 'user/updateAll',
    payload: user,
});

export const updateProfileAction = (newProfileData) => ({
    type: 'user/updateProfile',
    payload: newProfileData,
});

export const changeProfilePhotoAction = (photoLink) => {
    return {
        type: 'user/changeProfilePhoto',
        payload: photoLink,
    };
};

export const changeCardBgColorAction = (bgColor) => ({
    type: 'user/changeCardBg',
    payload: bgColor,
});

export const changeCardFontColorAction = (fontColor) => ({
    type: 'user/changeCardFontColor',
    payload: fontColor,
});

export const changeCardInformationColorAction = (informationColor) => ({
    type: 'user/changeCardInformationColor',
    payload: informationColor,
});

export const changeFirstNameAction = (firstName) => ({
    type: 'user/changeFirstName',
    payload: firstName,
});

export const changeMiddleNameAction = (midName) => ({
    type: 'user/changeMiddleName',
    payload: midName,
});

export const changeLastNameAction = (lastName) => ({
    type: 'user/changeLastName',
    payload: lastName,
});

export const changeSuffixAction = (suffix) => ({
    type: 'user/changeSuffix',
    payload: suffix,
});

export const changeUserPositionAction = (position) => ({
    type: 'user/changeUserPosition',
    payload: position,
});

export const changeUserEmailsAction = (email) => ({
    type: 'user/changeUserEmails',
    payload: email,
})

export const changeUserWorkplacesAction = (workplaces) => ({
    type: 'user/changeUserWorkplaces',
    payload: workplaces,
});

export const changeUserEmailAction = (email) => ({
    type: 'user/changeUserEmail',
    payload: email,
});

export const changeUserNumberAction = (number) => ({
    type: 'user/changeUserNumber',
    payload: number,
});

export const changeUserAboutAction = (about) => ({
    type: 'user/changeUserAbout',
    payload: about,
});

export const changeUserLinksAction = (links) => ({
    type: 'user/changeUserLinks',
    payload: links,
});

export const changeUserVideoLinkAction = (videoLink) => ({
    type: 'user/changeUserVideoLink',
    payload: videoLink,
});